import {useEffect, useRef, useState} from "react";
import "whatwg-fetch";
const cdxFetch = (path, {headers, ...rest} = {}) => {
  const url = `${process.env.GATSBY_API_HOST}${path}`;
  const finalHeaders = {
    Accept: "application/vnd.codecks-v2",
    "Content-Type": "application/json",
    "X-Account": "auth",
    ...headers,
  };
  return fetch(url, {
    mode: "cors",
    credentials: "include",
    headers: finalHeaders,
    ...rest,
  }).then(res => {
    if (res.status >= 400) {
      return res.json().then(text => {
        const asError = text.error || (text.message && text.message.error);
        return Promise.reject(asError || JSON.stringify(text));
      });
    } else {
      return res.json();
    }
  });
};

export const useRequestHandler = ({path}) => {
  const [loading, setLoading] = useState(false);

  const requestHandler = data => {
    if (loading) return;
    setLoading(true);
    return cdxFetch(path, {body: JSON.stringify(data), method: "post"}).then(
      res => {
        setLoading(false);
        return res;
      },
      err => {
        setLoading(false);
        return Promise.reject(err);
      }
    );
  };

  return {
    loading,
    requestHandler,
  };
};

export const useFetchData = ({path, method}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const meRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    const me = {};
    meRef.current = me;
    cdxFetch(path, {method}).then(
      res => {
        if (meRef.current === me) {
          setLoading(false);
          setData(res);
        }
      },
      err => {
        if (meRef.current === me) {
          setLoading(false);
          setError(err);
        }
      }
    );
  }, [path, method]);

  return {
    loading,
    data,
    error,
  };
};

export default cdxFetch;
