// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-auth-unsubscribe-js": () => import("./../../../src/pages/auth/unsubscribe.js" /* webpackChunkName: "component---src-pages-auth-unsubscribe-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-bricks-js": () => import("./../../../src/pages/$bricks.js" /* webpackChunkName: "component---src-pages-bricks-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-discord-js": () => import("./../../../src/pages/discord.js" /* webpackChunkName: "component---src-pages-discord-js" */),
  "component---src-pages-features-cards-decks-and-projects-js": () => import("./../../../src/pages/features/cards-decks-and-projects.js" /* webpackChunkName: "component---src-pages-features-cards-decks-and-projects-js" */),
  "component---src-pages-features-conversations-and-notifications-js": () => import("./../../../src/pages/features/conversations-and-notifications.js" /* webpackChunkName: "component---src-pages-features-conversations-and-notifications-js" */),
  "component---src-pages-features-discord-integration-js": () => import("./../../../src/pages/features/discord-integration.js" /* webpackChunkName: "component---src-pages-features-discord-integration-js" */),
  "component---src-pages-features-due-dates-js": () => import("./../../../src/pages/features/due-dates.js" /* webpackChunkName: "component---src-pages-features-due-dates-js" */),
  "component---src-pages-features-hero-cards-and-journeys-js": () => import("./../../../src/pages/features/hero-cards-and-journeys.js" /* webpackChunkName: "component---src-pages-features-hero-cards-and-journeys-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-metrics-and-reports-js": () => import("./../../../src/pages/features/metrics-and-reports.js" /* webpackChunkName: "component---src-pages-features-metrics-and-reports-js" */),
  "component---src-pages-features-milestones-js": () => import("./../../../src/pages/features/milestones.js" /* webpackChunkName: "component---src-pages-features-milestones-js" */),
  "component---src-pages-features-open-development-js": () => import("./../../../src/pages/features/open-development.js" /* webpackChunkName: "component---src-pages-features-open-development-js" */),
  "component---src-pages-features-search-and-order-js": () => import("./../../../src/pages/features/search-and-order.js" /* webpackChunkName: "component---src-pages-features-search-and-order-js" */),
  "component---src-pages-features-slack-integration-js": () => import("./../../../src/pages/features/slack-integration.js" /* webpackChunkName: "component---src-pages-features-slack-integration-js" */),
  "component---src-pages-features-source-control-integration-js": () => import("./../../../src/pages/features/source-control-integration.js" /* webpackChunkName: "component---src-pages-features-source-control-integration-js" */),
  "component---src-pages-features-time-tracking-js": () => import("./../../../src/pages/features/time-tracking.js" /* webpackChunkName: "component---src-pages-features-time-tracking-js" */),
  "component---src-pages-features-unique-hand-design-js": () => import("./../../../src/pages/features/unique-hand-design.js" /* webpackChunkName: "component---src-pages-features-unique-hand-design-js" */),
  "component---src-pages-features-vs-hacknplan-js": () => import("./../../../src/pages/features/vs-hacknplan.js" /* webpackChunkName: "component---src-pages-features-vs-hacknplan-js" */),
  "component---src-pages-features-vs-jira-js": () => import("./../../../src/pages/features/vs-jira.js" /* webpackChunkName: "component---src-pages-features-vs-jira-js" */),
  "component---src-pages-features-vs-trello-js": () => import("./../../../src/pages/features/vs-trello.js" /* webpackChunkName: "component---src-pages-features-vs-trello-js" */),
  "component---src-pages-game-production-podcast-js": () => import("./../../../src/pages/game-production-podcast.js" /* webpackChunkName: "component---src-pages-game-production-podcast-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-steam-bot-js": () => import("./../../../src/pages/steam-bot.js" /* webpackChunkName: "component---src-pages-steam-bot-js" */),
  "component---src-templates-changelog-js": () => import("./../../../src/templates/Changelog.js" /* webpackChunkName: "component---src-templates-changelog-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/Faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

