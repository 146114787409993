import React from "react";
import MiniEvent from "../lib/mini-event";
import Cookies from "cookies-js";
import cdxFetch from "../lib/fetch";

let appInfo = {accounts: null, user: null, steamyGuilds: []};

const events = new MiniEvent();

export const setAppInfo = info => {
  appInfo = info;
  events.emit(info);
};

export const useAppInfo = () => {
  const [state, set] = React.useState(appInfo);
  React.useEffect(() => events.addListener(set), []);
  return state;
};

export const loadAppInfo = () => {
  const token = Cookies.get("auth-token");
  const headers = token ? {"X-Auth-Token": token} : {};
  return cdxFetch(`/services/get-accounts`, {headers}).then(
    response => {
      const appInfo = {
        accounts: response.accounts,
        user: {name: response.name, email: response.email},
        steamyGuilds: response.steamyGuilds,
      };
      setAppInfo(appInfo);
      return appInfo;
    },
    e => {
      if (e !== "Not authorized") console.error(e);
    }
  );
};
