import React from "react";
import qs from "qs";
import Helmet from "react-helmet";
import {useAffiliateCode} from "./manage-cookies";

const reducer = (state, action) => action(state);

const initialState = {
  codeInfo: null,
  hideHint: true,
};

export const codeInfoActions = {
  activateCode: (dispatch, codeInfo) => dispatch(state => ({...state, codeInfo, hideHint: false})),
  setCodeInfo: (dispatch, codeInfo) => dispatch(state => ({...state, codeInfo})),
  hideHint: dispatch => dispatch(state => ({...state, hideHint: true})),
};

const StateContext = React.createContext(initialState);
const DispatchContext = React.createContext(null);

export const CodeInfoProvider = ({pageContextCode, location, children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const params = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : {};
  const code = params.code || pageContextCode;
  useAffiliateCode({code, params, dispatch});

  // hide hint if user navigates to other page
  React.useEffect(() => {
    if (state.hideHint === false) codeInfoActions.hideHint(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <React.Fragment>
          {code && (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          )}
          {children}
        </React.Fragment>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useCodeInfoState = () => React.useContext(StateContext);
export const useCodeInfoDispatch = () => React.useContext(DispatchContext);
