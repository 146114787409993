import React from "react";
import {isIE11} from "../lib/utils";
import {CodeInfoProvider} from "../features/affiliate/CodeInfoProvider";
import {loadAppInfo} from "../features/AppInfo";

const Root = ({children, pageContext, location, navigate}) => {
  React.useEffect(() => {
    loadAppInfo();
  }, []);

  React.useEffect(() => {
    if (isIE11()) document.body.className += " is-ie";
  }, []);

  React.useEffect(() => {
    const domains =
      process.env.GATSBY_GA_AUTO_LINK_DOMAINS && process.env.GATSBY_GA_AUTO_LINK_DOMAINS.split(",");
    if (domains && typeof window.gtag === "function") {
      window.gtag("config", process.env.GATSBY_GA_ID, {linker: {domains}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CodeInfoProvider pageContextCode={pageContext.affiliateCode} location={location}>
      {children}
    </CodeInfoProvider>
  );
};

export default Root;
