import React from "react";
import Cookies from "cookies-js";
import fetch from "../../lib/fetch";
import uuidv4 from "uuid/v4";
import qs from "qs";
import {codeInfoActions, useCodeInfoState, useCodeInfoDispatch} from "./CodeInfoProvider";

const persistAffCookieName = "visitor";
const sessionAffCookieName = "visit";

const utmFields = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

const tryParse = key => {
  try {
    const data = Cookies.get(key);
    if (!data) return null;
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

const analyzeIfNewVisit = code => {
  // using session cookies to separate different visits
  const val = Cookies.get(sessionAffCookieName);
  if (code !== val) {
    Cookies.set(sessionAffCookieName, code); // no expires == session cookie
    return true;
  } else {
    return false;
  }
};

export const verifyCodeInfo = ({code, visitorUuid, existingSession}) => {
  const search = qs.stringify({code, visitorUuid, existingSession});
  return fetch(`/services/code-info?${search}`).then(response => {
    return {code, visitorUuid, ...response};
  });
};

const conciliateUtmData = (params, rawExistData) => {
  const existData = rawExistData || {};
  const needToUpdate = utmFields.some(f => params[f] && params[f] !== existData[f]);
  if (needToUpdate) {
    utmFields.forEach(f => {
      existData[f] = params[f];
    });
    Cookies.set(persistAffCookieName, JSON.stringify(existData), {
      expires: 60 * 60 * 24 * 90,
    });
  }
};

export const useAffiliateCode = ({code, params, dispatch}) => {
  React.useEffect(() => {
    const existData = tryParse(persistAffCookieName);
    conciliateUtmData(params, existData);
    if (!code && (!existData || !existData.code)) return;
    const data = code
      ? {
          ...existData,
          uuid: (existData && existData.uuid) || uuidv4(),
          code,
        }
      : existData;
    if (code) {
      Cookies.set(persistAffCookieName, JSON.stringify(data), {
        expires: 60 * 60 * 24 * 90,
      });
    }
    const isNewVisit = code && analyzeIfNewVisit(code);
    verifyCodeInfo({code: data.code, visitorUuid: data.uuid, existingSession: !isNewVisit}).then(
      codeInfo => {
        if (code) {
          codeInfoActions.activateCode(dispatch, codeInfo);
        } else {
          codeInfoActions.setCodeInfo(dispatch, codeInfo);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useCodeUpdaterOnMount = () => {
  const {codeInfo} = useCodeInfoState();
  const dispatch = useCodeInfoDispatch();
  React.useEffect(() => {
    if (codeInfo && codeInfo.ok && codeInfo.code && codeInfo.visitorUuid) {
      verifyCodeInfo({
        code: codeInfo.code,
        visitorUuid: codeInfo.visitorUuid,
        existingSession: true,
      }).then(newCodeInfo => {
        codeInfoActions.setCodeInfo(dispatch, codeInfo);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const clearCodeData = () => {
  const data = tryParse(persistAffCookieName);
  if (data && data.code) {
    const {code, ...newData} = data;
    Cookies.set(persistAffCookieName, JSON.stringify(newData));
  }
};

export const useSourceInfo = () => {
  const data = tryParse(persistAffCookieName);
  const {codeInfo} = useCodeInfoState();
  if (data) {
    const source = {
      affiliate_code: data.code,
      visitor_uuid: data.uuid,
    };
    utmFields.forEach(f => {
      if (data[f]) source[f] = data[f];
    });
    return source;
  } else if (codeInfo) {
    // should only apply if cookies are disabled
    return {
      affiliate_code: codeInfo.code,
    };
  } else {
    return {};
  }
};
